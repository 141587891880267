import React from 'react';
import logo from '../../assets/img/logo_wethinkLearning.png';
import './logo.css';

 const wLearningLogo =() =>{
    return(
        
        <img src={logo} alt="WeThink Learning" className= "logoStyle"/>
        
    )
}

export default wLearningLogo;